.box1 {
  background-color: rgb(244, 242, 238);

}




.mainSlider .swiperContainer2 {
  background-image: url("../public/assets/img/slider/slider-1.jpg");
  width: 100%;
  height: 800px;
  background-position: center;
  background-size: cover;
}

.mainSlider .swiperContainer2 .swipermain2 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 750px;
}

.mainSlider .swiperContainer {
  background-image: url("../public/assets/img/slider/slider-3.jpg");
  width: 100%;
  height: 800px;
  background-position: center;
  background-size: cover;
}

.mainSlider .swiperContainer .swipermain {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 750px;
}

.vavbutn {
  display: none;
}


.vavbutn {
  text-align: right;
  display: block;
  background: transparent;
  font-size: 25px;
  color: rgb(17, 17, 17);
  border: transparent;
  outline: none;
  margin-bottom: 12px;
}

@media (max-width: 560px) {


  .vavbutn {
    display: block;
    background: transparent;
    font-size: 25px;
    color: rgb(14, 13, 13);
    border: transparent;
    outline: none;
    margin-bottom: 12px;
  }
}




/*=================================Header start=============================*/
.navbar {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 90px;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

#navbarSupportedContent ul {
  padding-bottom: 10px;
}

.nav-item .nav-link {
  list-style: none;
  text-decoration: none;
  position: relative;
  color: rgb(245, 239, 239);
  font-size: 18px !important;
  font-weight: 600;
}

.nav-link.active {
  border-color: #0c0c10;
}

.d-flex {
  text-decoration: none;
}

.logo-wrapper img {
  height: 65px;
}

.logo-wrapper h1 {
  color: white;
  font-size: 20px;
  align-items: center;

}

.vavbutn {
  display: none;
}

.desklink ul {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 12px; */
}


.desklink ul li .nhlink {
  text-decoration: none;
  color: white;
  /* font-weight: 600; */
}

.outslidmain {
  background-color: #59fefd;
  border-radius: 50%;
  padding: 5px;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outslidmain .outslidmainImg {
  width: 85%;
  /* border-radius: 50%; */
}


.image1 {
  background-repeat: no-repeat;
  overflow: hidden;
}

.imgCard {
  position: absolute;
  top: 210px !important;
  left: 150px !important;
  width: 27%;
  background-color: #fff !important;
}

.imgCard1 {
  position: absolute;
  top: 210px !important;
  left: 150px !important;
  height: auto;
  width: 26%;
  text-align: center;

  background-color: transparent;
  padding: 10px;
  padding-bottom: 28px;
  letter-spacing: 3px;
  z-index: 90;
  border-radius: 5px;
}

.imgCard1 h2 {
  padding-top: 28px;
  font-size: 34px;

  font-weight: 600;
  letter-spacing: 1.5px;
  color: #112042;
}

.imgCard .imgCardText {
  padding-top: 15px;
  font-size: 34px;

  font-weight: 600;
  letter-spacing: 0.6px;
  color: #112042;
  text-align: center;
}

.imgCard h2 {
  font-size: 40px;

  color: #112042;
  text-align: center;
}

.imgCard1 .paragraph {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgCard1 .paragraph .paragraph1 {
  padding: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 16px;

  letter-spacing: 0px;
  word-spacing: 0px;
  text-align: center;
}


.imgCard1 .imgcardButton {
  margin: 1px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  border: none;
  border-radius: 5px;
  color: #112042;
  letter-spacing: 0.6px;

  text-decoration: none;
}

.imgCard1 .imgcardButton:hover {
  color: #ff808b;
}

.imgCard1 .dash {
  display: block;
  width: 45px;
  height: 1px;
  background-color: #ff808b;
  margin: 15px auto;
  margin-top: 9px;
}

.imgCard1 .dash2 {
  display: block;
  width: 45px;
  height: 1px;
  background-color: #ff808b;
  margin: 15px auto;
}

.imgCard h1 {
  margin-bottom: 10px;
  font-size: 80px !important;

  text-shadow: 1px 1px 1px gray;
  color: whitesmoke;
  line-height: 100%;
}

.imgCardButton {
  margin: 1px;
  padding: 6px;
  background-color: rgb(4, 14, 40);
  font-size: 10px;
  text-align: center;
  float: right;
  border: none;
  border-radius: 5px;
  color: white;
  letter-spacing: 0.6px;

}

.img-card-button span {
  font-size: 12px;
  color: white;
  margin-left: 5px;
}

.box {
  margin: 10px;
  background-color: white;
  height: auto;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
}

.box>.rows {
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: row;
}

.box>.rows>.column {
  margin: -3px;
  padding-right: 7px;
  margin-top: -7px;
}

.box>.rows>.column>h1 {
  text-align: left;

  font-style: italic;
  border-radius: 10px;
  font-size: 34px;
}

.box .rows .column .join {
  font-size: 26px;
  color: #112042;

}

.box .rows .column .join {
  transition: 1s;
}

.box-link {
  display: flex;
  justify-content: center;
  text-align: center;
  color: black;
}

.box-link>li {
  cursor: pointer;
  font-size: 22px;
  list-style: none;
}

.box-link li .link {
  color: rgb(4, 14, 40);
  padding: 7px;
}

.box-link li .link :hover {
  transform: translate(-10px, -10px);
  transition: 1.2s;
}

.box-3 {
  background-color: rgb(233, 228, 228);
  height: auto;
}

.box-3 .rows {
  display: grid;
  grid-template-columns: 6fr 6fr;
}

.box-3 .rows .column1 {
  background-color: #0c3157;
}

.box-3 .rows .column2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-3 .rows .column2 .column2Img {
  /* height: 100%; */
  width: 100%;
}

.box-3 .rows .column2 .column2Img>img {
  width: 100%;
  /* height: 100%; */
}

.box-3 .column {
  display: block;
  text-align: center;
}

.box-3 .column>p {
  margin: 20px;
  color: #fff;
  font-weight: bold;

}

.box-3 .column>h1 {
  font-weight: 500;
  color: white;

}

.box-3 .column>h3 {
  color: #fff;
  justify-content: center;
  text-align: center;
  padding: 25px;


  margin-top: 50px;
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 1.8;
}

.box-3 .column>h5 {
  text-align: center;
  color: #fff;
  font-size: 18px;

}

.box-3 .column>h2 {
  font-size: 20px;
  color: #fff;

}

.box-4 {
  background-color: white;
  padding-top: 40px;
  margin-bottom: -125px;
  height: 350px;
}

.box-4 h3 {
  text-align: justify;
  color: #112042;

  font-size: 15px;
  font-weight: bold;
}

.box-4 h1 {

  text-align: justify;

  color: #112042;
  font-size: 56px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.04em;
  line-height: 1;
}

.box-4 h2 {
  padding-top: 25px;
  text-align: center;
  font-size: 30px;

}

.box-4 h2:hover {
  text-decoration: 3px underline #eec3af;
  transition: 1s;
}

.box-5 {
  overflow: hidden;
  position: relative;
  height: 800px;
}

.box-5 .card {
  position: absolute;
  top: 60px;
  left: 120px;
  height: 550px;
  width: 600px;
  background: transparent;
}

.box-5 .card>h1 {
  color: rgb(224, 215, 215);
  font-size: 70px;

  padding: 50px;
  letter-spacing: 4px;
}

.box-5 .card>button {
  margin: 5px;
  padding: 12px;
  width: 200px;
  background-color: rgb(97, 215, 220);
  text-align: center;
  margin-left: 100px;

  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  color: rgb(17, 16, 14);
  cursor: pointer;
}

.box-6 {
  background-color: rgba(246, 250, 251, 0.84);
}

.box-6 h1 {
  color: #112042;
  text-align: center;
  padding-top: 50px;
  font-size: 32px;
  /* font-family: "begum",serif; */

}

.box-6 h1>span {
  color: red;

}

.box-6 p {
  text-align: center !important;
  padding: 6px;
  font-size: 20px;
  margin-top: 22px;
  letter-spacing: 1px;

}

/* ================================Swiper============================= */
.containerSlider {
  margin-top: 20px;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.containerSlider .MainSlider {
  width: 75%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.containerSlider .MainSlider h3 {
  font-size: 32px;
  line-height: 1;
  /* font-family: 'Alkatra', cursive;
    font-family: "begum",serif; */

  font-weight: 100;
}

.containerSlider .MainSlider span {
  color: rgb(250, 56, 104);
  font-size: 42px;
  font-weight: bold;
  /* position: absolute; */
}

.containerSlider .MainSlider .cama1 {
  color: rgb(250, 56, 104);
  font-size: 42px;
}

.containerSlider .MainSlider p {
  font-size: 24px;

  color: rgb(250, 56, 104);
}

/* =========================HomeCard Start===========================*/
.homeCard {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeCard .homeCardMain {
  width: 100%;
  height: auto;
  padding: 10px;
}

.homeCard .left {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeCard .left h6 {
  font-size: 16px;
  color: rgb(4, 14, 40);
  font-weight: bold;
  opacity: 0.9;
}

.homeCard .left h1 {
  font-size: 52px;

  color: rgb(17, 26, 65);
  line-height: 50px;
}

.homeCard .left p {
  font-size: 30px;
}

.homeCard .leftText {
  padding: 0;
}

.homeCard .leftText h2 {
  color: rgb(251, 108, 151);
  font-size: 20px;

}

.homeCard .leftText h5 {

  color: rgb(17, 26, 65);
  font-weight: bolder;
  font-size: 26px;
}

.homeCard .right {
  padding: 10px;
}

.homeCard .right .homeCardImg {
  width: 100%;
  height: 450px;
}

.homeCard .right .homeCardImg img {
  width: 100%;
  /* height: 100%; */
  cursor: pointer;
}

.homeCard .right .rightText {
  padding: 0;
}

.homeCard .right .rightText a {
  text-decoration: none;
}

.homeCard .right .rightText h6 {
  color: rgb(251, 108, 151);

}

.homeCard .right .rightText h3 {
  color: rgb(17, 26, 65);

  padding: 6px;
}

.homeCard .right .rightText p {
  color: black;

}

.homeCard .right .homeCardImg img:hover {
  transform: scale(0.9);
  transition: 1.5s;
}

.homeCard .right .rightText:hover {
  transform: scale(0.8);
  transition: 1.2s;
}

/* =========================HomeCard End=========================== */
/* ===========================DisplayPage End=================== */

/* ================================My Story Start============================= */
.imgCard {
  position: inherit;
  top: 15% !important;
  left: 780px !important;
  height: auto;
  width: 26%;
  background-color: transparent;
  padding: 10px;
  letter-spacing: 3px;
  z-index: 90;
  border-radius: 5px;
}

.imgCard .dash {
  display: block;
  width: 50px;
  height: 1px;
  background-color: #ff808b;
  margin: 7px auto;
}

.imgCard .dashbelow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 60px;
  padding-bottom: 15px;
}

.imgCard .dashbelow1 {
  display: block;
  width: 60px;
  height: 1px;
  background-color: #ff808b;
  margin: 7px auto;
}

.imgCard .imgcardButton {
  margin: 1px;
  padding-left: 90px;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  border: none;
  border-radius: 5px;
  color: #112042;
  letter-spacing: 0.6px;

  text-decoration: none;
}

.imgCard .imgcardButton:hover {
  color: #ff808b;
}

.imgCard .imgCardTextHeadPara {
  /* padding: 32px; */
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 18px;
  font-weight: 500;

  color: #112042;
  letter-spacing: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgCard .imgCardTextPara {
  padding: 32px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  /* font-family: Verdana, "Geneva", Tahoma, sans-serif !important; */

  letter-spacing: 0px;
  text-align: center;
  color: #1d1d1d;
  /* justify-content: center; */
}

.mystoryImg .banner {
  background-repeat: no-repeat;
  overflow: hidden;
}

.mystoryImg .banner img {
  width: 100%;
}

/* .text {
    position: absolute;
    top: 400px;
    right: 40px;
    text-transform: uppercase;
    height: 70%;
    width: 37%;
    background-color: transparent;
    color: rgb(4, 14, 40);
    font-size: 30px;
    padding: 10px;
    letter-spacing: 2px;
    
    z-index: 90;
  } */

.text h2 {
  font-size: 70px;

  text-align: center;
}

.text h2>span {
  text-decoration: 2px #ff808b underline;
}

.mystoryCard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.mystoryCard .text1 {
  padding: 2px;
}

.mystoryCard .text1 h1 {
  font-size: 70px;
  color: rgb(4, 14, 40);
  font-family: "merriweather";
}

.mystoryCard2 {
  margin: 2px;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mystoryCard2 .text2 {
  padding: 60px;
  width: 75%;
  height: auto;
}

.mystoryCard2 .text2 h6 {
  font-size: 24px;

  padding: 8px;
}

.mystoryCard2 .text2 p {
  text-align: justify;
  font-size: 20px;
  font-weight: 500;
  font-family: "Times New Roman", Times, serif;
  color: #ff808b;
}

.mystoryCard3 {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mystoryCard3 .mystorylogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
}

.myapprochCard3 .myapprochText2 .mybio h2 {
  font-size: 34px !important;
  color: #112042;
  text-align: left;
  text-decoration: 3px #112042 underline;

  font-weight: 600;
  cursor: pointer;
}

.myapprochCard3 .myapprochText2 .mybio h2:hover {
  text-decoration: 3px #ff808b underline;
  transition: 1s;
}

.myapprochCard3 .myapprochText2 .mybio p {
  font-size: 17px !important;

  color: #060264;
  /* padding-top: 8px; */
  cursor: pointer;
}

.mystoryCard3 .mystorylogo .mystoryImg {
  height: auto;
  width: 100%;
}

.mystoryCard3 .mystorylogo .mystoryImg img {
  padding: 10px;
  width: 70%;
  height: auto;
}

.approchSlider {

  color: #112042;
}

/* ==========laqsha start========================================== */
.faq-area .faq-content .accordion {
  margin: 0;
  padding: 0;
}

.faq-area .faq-content .accordion p {
  display: none;
  padding: 20px 15px 15px;
  margin-bottom: 0;
}

.faq-area .faq-content .accordion a {
  width: 100%;
  display: block;
  cursor: pointer;
  padding: 15px 15px 15px 18px;
  border: 1px solid #221668;
  font-weight: 500;
  border-radius: 5px;
  font-size: 17px;
  color: #221668;
}

.faq-area .faq-content .accordion a:after {
  position: absolute;
  right: 20px;
  content: "+";
  top: 10px;
  color: #000;
  font-size: 25px;
  font-weight: 500;
}

.faq-area .faq-content .accordion a.active {
  color: #fff;
  background-color: #221668;
  border: 1px solid #221668;
}

.faq-area .faq-content .accordion a.active:after {
  content: "-";
  font-size: 25px;
  color: #fff;
}

.faq-area .faq-content .accordion li {
  position: relative;
  list-style-type: none;
  margin-bottom: 30px;
  display: block;
}

.faq-area .faq-content .accordion li:first-child {
  border-top: 0;
}

.faq-area .faq-content .accordion li:last-child {
  margin-bottom: 0;
}



.faq-area .faq-img img {
  display: none;
}

.faq-area .faq-bottom {
  margin-top: 30px;
  text-align: center;
}

.faq-area .faq-bottom h3 {
  color: #221668;
  font-size: 30px;
  margin-bottom: 15px;
  max-width: 485px;
  margin-left: auto;
  margin-right: auto;
}

.faq-area .faq-bottom a {
  display: inline-block;
  color: #fff;
  background-color: #fe4a55;
  border-radius: 5px;
  padding: 12px 25px;
  font-weight: 500;
}

.faq-area .faq-bottom a:hover {
  background-color: #221668;
}


/* ================================Program Page Start============================= */


.employer-area {
  padding-top: 30px;
}

.employer-area .section-title {
  text-align: center;
}

.employer-area .section-title h2 {

  padding-bottom: 20px;
  color: #112042;
}

.employer-item {
  margin-bottom: 30px;
  border-radius: 5px;
  border: 1.5px dashed #221668;
  -webkit-box-shadow: 0 0 20px 0 #dddddd73;
  box-shadow: 0 0 20px 0 #dddddd73;
  position: relative;
  padding: 15px 25px 25px 25px;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.employer-item:hover {
  background-color: #92b4d1;
  -webkit-transform: translate(0, -10px);
  transform: translate(0, -10px);
}

/* .employer-item:hover  { 
    color: white;
    -webkit-transform: translate(0, -10px);
    transform: translate(0, -10px);
  } */
.employer-item h3 {
  margin-bottom: 7px;
  font-size: 22px;
  font-weight: 500;
  color: #112042;
  font-family: "quicksand", sans-serif;
  text-align: justify;
}

.employer-item ul {
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
}

.employer-item ul li {
  list-style-type: none;
  display: inline-block;
  color: #7d789b;
  margin-right: 10px;
}

.employer-item ul li i {
  display: inline-block;
  font-size: 16px;
  position: relative;
  top: 1px;
  margin-right: 5px;
}

.employer-item ul li:last-child {
  color: #00d2ff;
  font-size: 14px;
  margin-right: 0;
}

.employer-item p {
  margin-bottom: 18px;
  color: #000;
  text-align: justify;
}

.employer-item .span-one {
  color: #008aff;
  background-color: #e4e4e4;
  border-radius: 5px;
  padding: 4px 10px;
  font-size: 13px;
  display: inline-block;
}

.storySlider {
  margin-top: 10px;

  font-weight: 600;
  color: #112042;
  /* border: 2px solid red; */
}

/* ================ */
.programText {
  background-color: #fff;
}

.programCard3 {
  width: 100%;
  height: auto;
  background-color: #fff;
}

.programCard3 .programText2 {
  display: grid;
  grid-template-columns: 6fr 6fr;
}

.programCard3 .programText2 .left3 {
  width: 100%;
  padding: 30px;
}

/* .programCard3 .programText2 .left3 img{
    width: 100%;
    height: 100%;
    border-radius: 2px;
  } */
.programCard3 .programText2 .right3 .dash {
  display: block;
  width: 30px;
  height: 1px;
  background-color: #ff808b;
  margin: 25px auto;
}

.dash {
  display: block;
  width: 45px;
  height: 1px;
  background-color: #f9f6f6;
  margin: 25px auto;
}

.programCard3 .programText2 .right3 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right3 .right3In {
  width: 80%;
  /* height: 600px; */
}

.programCard3 .programText2 .right3 h1 {
  font-size: 32px;
  color: rgb(4, 14, 40);
  text-align: center;

}

.programCard3 .programText2 .right3 h3 {
  text-align: justify;
  padding: 8px;

  font-size: 17px;
  line-height: 30px;
  letter-spacing: 0.4px;
}

.programCard3 .programText2 .right3 h5 {
  text-align: justify;
  color: #fe4a55;
  font-size: 18px;

  font-weight: bold;
}

.programCard3 .programText2 .right3 .programCard3Btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(42, 39, 39);
  padding: 5px;

  font-weight: bold;
  text-decoration: none;
}

.programCard3 .programText2 .right3 .programCard3Btn:hover {
  text-decoration: 2px #ff808b underline;
  transition: 1s;
}

.programSlider {
  color: #112042;
}

.programCard4 {
  width: 100%;
  /* height: 60vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aliceblue;
}

.programCard4 .programText3 {
  width: 60%;
  height: 100%;
}

.programCard4 .programText3 h3 {
  text-align: justify;
  font-size: 20px;

  color: rgb(4, 14, 40);
  padding: 15px;
}

.programCard4 .programText3 h1 {
  text-align: center;
  font-size: 42px;

  color: rgb(14, 25, 53);
  font-weight: 520;
}

.programCard4 .programText3 .programCard4Btn {
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 400;
  font-size: 35px;
  padding: 20px;
  color: rgb(14, 25, 53);
  cursor: pointer;
  text-decoration: none;
}

.programCard4 .programText3 .programCard4Btn:hover {
  color: #fe4a55;
}

.programCard5 {
  background-color: aliceblue;
}

/* ================================Program Page End============================= */
/* ================================My Approch Start============================= */
.myapprochImg .banner2 {
  background-repeat: no-repeat;
  overflow: hidden;
}

.myapprochCard {
  position: absolute;
  top: 400px;
  right: 40px;
  text-transform: uppercase;
  height: 70%;
  width: 37%;
  background-color: transparent;
  /* color: rgb(22, 33, 75); */
  font-size: 30px;
  padding: 10px;
  letter-spacing: 2px;

  z-index: 90;
}

.myapprochCard h2 {
  margin-top: 90px;
  margin-bottom: 10px;
  font-size: 75px;

  text-shadow: 1px 1px 1px gray;
  text-align: center;
  color: #112042;
}

.myapprochCard1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #fafafa;
}

.myapprochCard1 .myapprochText {
  padding: 61px;
}

.myapprochCard1 .myapprochText h1 {
  font-size: 34px;
  color: #112042;

  font-weight: bold;
}

.myapprochCard2 {
  height: auto;
  width: 100%;
}

.myapprochCard2 .myapprochText1 {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.myapprochCard2 .myapprochText1 .left3 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.left3 .left3In {
  height: auto;
  padding: 60px;
  padding-left: 260px;
}

.myapprochCard2 .myapprochText1 .left3 h1 {
  font-size: 50px !important;
  color: #ff808b;
  text-align: center;

}

.myapprochCard2 .myapprochText1 .left3 h3 {
  text-align: justify;
  padding: 8px;

  font-size: 18px !important;
  line-height: 20px;
  letter-spacing: 0.4px;
}

.myapprochCard2 .myapprochText1 .right3 {
  width: 70%;
  padding: 30px;
}

.myapprochCard2 .myapprochText1 .right3 img {
  width: 100%;
  height: auto;
  border-radius: 2px;
}

.myapprochCard3 {
  margin: 2px;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aliceblue;
}

.myapprochCard3 .myapprochText2 {
  padding: 50px;
  width: 80%;
  height: auto;
}

.myapprochCard3 .myapprochText2 h2 {
  font-size: 34px !important;
  color: #212529;
  text-align: center;

  letter-spacing: 0.3px;
}

.myapprochCard3 .myapprochText2 p {
  font-size: 17px !important;

  padding: 8px;
}

.myapprochCard3 .myapprochText2 p span {
  color: rgb(249, 127, 129);
}

.myapprochCard4 {
  width: 100%;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myapprochCard4 .myapprochText3 {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myapprochCard4 .myapprochText3 .box1 {
  width: 100%;
  margin: 2px;
}

.myapprochText3 .box1 .box1Img {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: 50%; */
}

.myapprochText3 .box1 .box1Head>h3 {
  color: #112042;
  font-size: 14px;
  text-align: justify;

  font-weight: 600;
  padding: 10px;
}

.myapprochText3 .box1 .box1Para>p {
  font-size: 16px;
  color: rgb(31, 31, 31);
  text-align: justify;

  font-weight: 500;
}

/* ================================My Approch End============================= */
/* ================================More Start============================= */
.moreCard {
  background-color: rgb(4, 14, 40);
}

.moreCard .BtnClose {
  position: absolute;
  top: 10px;
  left: 10px;
}

.moreCard .moreText {
  display: grid;
  grid-template-columns: 8.2fr 3.8fr;
}

.moreCard .moreText .moreLeft {
  padding: 0px;
}

.moreCard .moreText .moreLeft .top {
  display: flex;
  line-height: 40px;
  justify-content: center;
  align-items: center;
}

.moreText .moreLeft .top .menu {
  list-style: none;
  text-align: center;
}

.moreText .moreLeft .top .menu .morePageLink {
  text-decoration: none;
  color: white;
  font-size: 26px;

  opacity: 0.9;
  letter-spacing: 1px;
}

.moreCard .moreText .moreLeft .top1 {
  padding: 0px;
}

.moreText .moreLeft .top1 .utilityMenu {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.moreText .moreLeft .top1 .utilityMenu .morePageLink1 {
  text-decoration: none;
  color: white;
  font-size: 20px;

  opacity: 0.9;
  letter-spacing: 1px;
  cursor: pointer;
}

.moreText .moreLeft .top2 {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.moreText .moreLeft .top2 .top2Logo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.moreText .moreLeft .top2 .top2Logo img {
  width: 20%;
  padding: 10px;
}

.moreText .moreLeft .top2 .top2Logo p {
  text-decoration: none;
  color: white;
  font-size: 22px;

  letter-spacing: 0.6px;
  opacity: 0.9;
  padding-top: 10px;
}

.moreText .moreLeft .top2 .social {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.moreText .moreLeft .top2 .social li {
  list-style: none;
  padding: 6px;
}

.moreCard .moreText .moreRight {
  width: 100%;
  /* height: 75%; */
  background-color: rgb(248, 237, 220);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.moreCard .moreText .moreRight .moreRightImg {
  /* height: 90%; */
  width: 95%;
}

.moreCard .moreText .moreRight .moreRightImg img {
  width: 100%;
  /* height: 100%; */
  border-radius: 5px;
}

.moreCard .moreText .moreRight .moreRightCard {
  position: absolute;
  top: 200px;
  left: 4px;
  background-color: white;
  opacity: 0.9;
  box-shadow: 1px 2px 1px 2px whitesmoke;
}

.moreCard .moreText .moreRight .moreRightCard h2 {
  color: #112042;
  font-size: 52px;

  font-weight: 540;
  opacity: 0.9;
  letter-spacing: 0.4px;
  padding: 10px;
  line-height: 55px;
}

/* ================================More End=============================== */
/* ================================Contact Start=============================== */
.contact-form-area .form-item {
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fafafa;
  padding: 30px;
  border-radius: 5px;
  position: relative;
}

.contact-form-area .form-item h2 {
  font-size: 30px;
  margin-bottom: 35px;
  color: #112042;
  position: relative;
  padding-bottom: 14px;
}

.contact-form-area .form-item h2:before {
  position: absolute;
  content: "";
  width: 100px;
  height: 3px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #112042;
  margin-left: auto;
  margin-right: auto;
}

.contact-form-area .form-item h2:after {
  position: absolute;
  content: "";
  width: 40px;
  height: 3px;
  left: 0;
  right: 0;
  bottom: -6px;
  background-color: #112042;
  margin-left: auto;
  margin-right: auto;
}

.contact-form-area .form-item .form-group {
  margin-bottom: 30px;
  text-align: left;
}

.contact-form-area .form-item .form-group label {
  margin-bottom: 10px;
  font-weight: 500;
  color: #221668;
}

.contact-form-area .form-item .form-group .form-control {
  height: 50px;
  border-radius: 5px;
  border: 1px solid #fff;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  padding-left: 20px;
  font-size: 14px;
}

.contact-form-area .form-item .form-group .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #221668;
}

.contact-form-area .form-item .form-group textarea {
  height: auto !important;
}

.contact-form-area .form-item .form-group .list-unstyled {
  margin-top: 10px;
  font-size: 14px;
  color: #dc3545;
}

.contact-form-area .form-item .btn {
  color: #fff;
  background-color: #221668;
  padding: 12px 30px;
  border-radius: 5px;
  border: 0;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  opacity: 1;
}

.contact-form-area .form-item .btn:hover {
  background-color: #fe4a55;
}

.contact-form-area .form-item .text-danger {
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 25px;
  color: #dc3545;
}

.contact-form-area .form-item .text-success {
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 25px;
  color: #28a745;
}

/* ================================Contact End=============================== */
/* ================================chat box start=============================== */


.chatbox {
  position: fixed;
  background: #fff;
  height: auto;
  bottom: 0;
  right: 0;
  border-radius: 10px;
  z-index: 999;
}

/* ================================chat box end=============================== */

/* ================================Media query Start=============================== */

@media (min-width: 1089px) {
  .navbar {
    width: 100%;
  }

  .image1 img {
    width: 100%;
  }

  .imgCard {
    position: absolute;
    top: 200px;
    left: 50px;
    color: rgb(4, 14, 40);
  }

  .imgCard h1 {
    font-size: 70px;
  }

  .imgCardButton {
    width: 100%;
    font-size: 17px;
  }

  .imgCardButton {
    width: 35%;
    font-size: 14px;
  }

  .box1>img {
    width: 100%;
  }

  .rows .column>h1 {
    font-size: 52px;
    width: 100%;
  }

  .box-3 .rows .column img {
    width: 100%;
  }

  .box-5 {
    height: 400px;
  }

  .box-5 img {
    width: 100%;
  }

  .myapprochCard2 .myapprochText1 .left3In h1 {
    font-size: 90px;
  }

  .myapprochCard2 .myapprochText1 .left3In h3 {
    font-size: 29px;
    line-height: 1.3;
  }

  .myapprochCard3 .myapprochText2 h2 {
    text-shadow: none;
    font-size: 50px;
  }

  .myapprochCard3 .myapprochText2 p {
    font-size: 34px;
  }
}

@media (max-width: 820px) {
  .desklink {
    display: none;
  }

  .moblink {
    position: absolute;
    top: 70px;
    right: 0;
    background: #212529c9;
    width: 60%;
    border-radius: 6px;
    text-align: center;
  }

  .moblink ul li {
    font-size: 18px;
    padding: 7px 0;
    list-style: none;
    color: #fff;
  }

  .vavbutn {
    text-align: right;
    display: block;
    background: transparent;
    font-size: 25px;
    color: white;
    border: transparent;
    outline: none;
    margin-bottom: 12px;
  }

  .image1 img {
    width: 100%;
  }

  .imgCard {
    /* position: absolute;
      top: 200px;
      left: 50px;
      color: rgb(4, 14, 40); */
    display: block;
  }

  .imgCard h1 {
    font-size: 70px;
  }

  .imgCardButton {
    width: 100%;
    font-size: 17px;
  }

  .imgCardButton {
    width: 100%;
    font-size: 12px;
  }

  .box1>img {
    width: 100%;
  }

  .rows .column>h1 {
    font-size: 30px;
    width: 100%;
  }

  .box-3 .rows .column img {
    width: 100%;
  }

  .box-5 {
    height: 400px;
  }

  .box-5 img {
    width: 100%;
  }

  .textslider {
    padding-top: -20px;
  }

  .mystoryslider {
    padding-top: 20px;
  }

  .mystoryImg .banner img {
    width: 100%;
  }

  .mystoryImg .text {
    position: absolute;
    top: 100px;
    left: 0;
  }

  .mystoryCard2 {
    height: auto;
  }

  .mystoryCard2 .text2 {
    width: 100%;
    padding: 15px;
  }

  .mystoryCard2 .text2 h6 {
    font-size: 28px;
  }

  .mystoryCard3 {
    height: 50%;
  }

  .mystoryCard4 .text3 {
    display: block;
  }

  .programCard1 {
    padding-top: 15px;
  }

  .programCard2 {
    padding-top: 20px;
  }

  .programCard3 {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .programCard3 .right3 .right3In h3 {
    line-height: 20px;
  }

  .programslider {
    padding-top: 15px;
  }

  .programCard4 {
    height: auto;
  }

  .myapprochslider {
    padding-top: 20px;
  }

  .myapprochImg .banner2 img {
    width: 100%;
  }

  .myapprochImg .myapprochCard {
    position: absolute;
    top: 194px;
    left: 16px;
  }

  .myapprochCard1 .myapprochText h1 {
    text-align: center;
  }

  .myapprochCard2 .myapprochText1 {
    display: block;
  }

  .myapprochCard2 .myapprochText1 .left3 {
    width: 100%;
  }

  .myapprochCard2 .myapprochText1 .left3In {
    width: 100%;
    height: auto;
  }

  .myapprochCard2 .myapprochText1 .left3In h1 {
    font-size: 56px;
  }

  .myapprochCard2 .myapprochText1 .left3In h3 {
    line-height: 30px;
    font-weight: 500;
  }

  .myapprochCard2 .myapprochText1 .right3 {
    width: 100%;
  }

  .myapprochCard3 {
    height: auto;
  }

  .myapprochCard3 .myapprochText2 {
    width: 100%;
  }

  .myapprochCard3 .myapprochText2 h2 {
    text-shadow: none;
    font-size: 40px;
  }

  .myapprochCard3 .myapprochText2 p {
    font-size: 25px;
  }

  .containerSlider {
    height: auto;
  }
}

@media (max-width: 560px) {
  .desklink {
    display: none;
  }

  .moblink {
    position: absolute;
    top: 70px;
    right: 0;
    background: white;
    width: 60%;
    border-radius: 6px;
    text-align: center;
  }

  .dropdown-item{
    text-align: center;
    font-weight: 700;
  }

  .moblink ul li {
    font-size: 18px;
    padding: 7px 0;
    list-style: none;
    color: #fff;
  }

  .vavbutn {
    display: block;
    background: transparent;
    font-size: 25px;
    color: rgb(9, 9, 9);
    border: transparent;
    outline: none;
    margin-bottom: 12px;
  }

  .image1 img {
    width: 100%;
  }

  .mystoryCard1 {

    margin-bottom: 5px;
  }

  .imgCard {
    width: 39%;
    height: 79%;
    top: 20px !important;
    left: 245px !important;
    bottom: 30px;
    word-spacing: 1px;
    position: absolute;
    padding-bottom: 0px;
  }

  .imgCard .imgCardText {
    text-align: center;
    padding-top: 0px;
    letter-spacing: 1.5px;
    font-weight: bold;

    font-size: 14px;
  }

  .imgCard .imgCardTextHeadPara {
    text-align: center;
    color: #1d1d1d;

    font-weight: 500;
    font-size: 11px;
  }

  .imgCard .imgCardTextPara {
    padding: 0px;
  }

  .imgCard .imgCardTextPara p {
    text-align: center;
    color: #1d1d1d;

    font-weight: 400;
    font-size: 8px;
    /* border: 1px solid red; */
    padding: 0px;
  }

  .imgCard .imgcardButton {
    padding: 0px;
    font-size: 10px;
    font-weight: bold;
    border-radius: 5px;
    color: #112042;
    /* border: 1px solid red; */
  }

  .imgCard .dashbelow {
    display: none;
    /* width: 206px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 0px; */
  }

  .imgCard .dashbelow .dashbelow1 {
    display: block;
    width: 60px;
    height: 1px;
    background-color: #ff808b;
    margin-right: 53px;
  }

  .imgCard1 h2 {
    text-align: center;
    padding-top: 0px;
    /* margin-right: 190px; */
    letter-spacing: 1.5px;

    font-size: 12px;
  }

  .imgCard1 .paragraph .paragraph1 {
    text-align: center;

    font-weight: 400;
    margin: 0 0 0px;
    font-size: 10px;
    padding: 0px;
  }

  .imgCard1 .imgcardButton {
    text-align: center;

    font-weight: 400;
    margin: 0 0 0px;
    font-size: 10px;
    padding: 0px;
  }

  .imgCard1 .dash {
    padding: 0px;
    margin: 0px 20px;
  }

  .imgCard1 {
    width: 39%;
    height: 74%;
    top: 30px !important;
    left: 20px !important;
    bottom: 30px;
    word-spacing: 1px;
  }

  /* .imgCard {
      display: none;
      width: 45%;
      height: 90%;
      top: 10px !important;
      left: 20px !important;
      bottom: 30px;
    } */

  .rows .column>h1 {
    width: 100%;
  }

  .box>.rows>.column>h1 {
    font-size: 14px;
  }

  .box>.rows>.column .box-link {
    display: none;
  }

  .containerSlider .MainSlider h3 {
    line-height: 24px;
    font-size: 20px;

  }

  .containerSlider .MainSlider span {
    color: rgb(250, 56, 104);
    font-size: 27px;
    font-weight: bold;
    /* position: absolute; */
  }

  .box1 {
    padding-top: 10px;
  }

  .box-3 .rows .column1 {
    width: 200%;
  }

  .box-3 .rows .column2 {
    display: none;
  }

  .box-3 .column>h3 {
    padding: 0px;
    font-size: 20px;
  }

  .box-4 h3 {
    font-size: 16px;
  }

  .box-4 h1 {
    font-size: 28px;
  }

  .box-4 h3 {
    font-size: 12px;
  }

  .box-5 {
    height: auto;
  }

  .box-5 img {
    width: 100%;
  }

  .box-5 .card {
    display: none;
  }

  .box-6 h1 {
    font-size: 32px;
    margin-top: 24px;
  }

  .footer {
    height: auto;
  }

  .footer .Mainfooter {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 30px;
  }

  .footer .Mainfooter .left {
    height: 20%;
    padding: 10px;
  }

  .footer .Mainfooter .right {
    height: 30%;
    padding: 10px;
  }

  .middle .footImg {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mystoryImg .banner img {
    width: 100%;
  }

  .mystoryImg .text {
    position: absolute;
    top: 100px;
    left: 0;
  }

  .mystoryImg .text h2 {
    font-size: 42px;
  }

  .mystoryCard {
    height: 35%;
    padding: 20px;
  }

  .mystoryCard .text1 h1 {
    font-size: 42px;
  }

  .mystoryCard2 {
    height: 30%;
  }

  .mystoryCard2 .text2 {
    width: 100%;
    padding: 15px;
  }

  .mystoryCard2 .text2 h6 {
    font-size: 22px;
  }

  .mystoryCard3 {
    height: 50%;
  }

  .mystoryCard4 .text3 {
    display: block;
  }

  .mystoryCard5 .text3 {
    width: 100%;
  }

  .mystoryCard5 .text3 h1 {
    font-size: 50px;
  }

  .programImg .banner1 img {
    width: 100%;
  }

  .programImg .programCard {
    position: absolute;
    top: -7px;
    left: 7px;
  }

  .programCard h2 {
    font-size: 12px;
    text-align: center;
  }

  .programCard1 {
    padding-top: 15px;
  }

  .programCard1 .peramhfgfg {
    text-align: left;
  }

  /* .programCard2{
    padding-top: 20px;
  } */
  .programCard2 .programText1 {
    display: block;
  }

  .programCard2 .programText1 .left2 .left2In {
    width: 90%;
    height: auto;
  }

  .programCard2 .programText1 .left2 .left2In h1 {
    font-size: 20px;
    margin-top: -95px;
  }

  .programCard2 .programText1 .right2 img {
    height: auto;
  }

  .programCard3 {
    padding-top: 10px;
  }

  .programCard3 .programText2 {
    display: block;
    height: auto;
  }

  .programCard3 .programText2 .right3 .right3In {
    height: auto;
  }

  .programslider {
    padding-top: 20px;
  }

  .programCard4 .programText3 {
    width: 100%;
  }

  .programCard4 .programText3 h3 {
    font-size: 20px;
  }

  .programCard4 .programText3 h1 {
    font-size: 33px;
  }

  .programCard4 .programText3 .programCard4Btn {
    font-size: 31px;
  }

  .myapprochImg .banner2 img {
    width: 100%;
  }

  .myapprochImg .myapprochCard {
    position: absolute;
    top: 91px;
    right: 33px;
  }

  .myapprochImg .myapprochCard h2 {
    font-size: 40px;
  }

  .myapprochCard1 {
    overflow: hidden;
    height: auto;
    margin-bottom: -60px;
    /* padding: 15px; */
  }

  .myapprochCard1 .myapprochText h1 {
    margin-top: -57px;
    font-size: 24px;
    text-align: center;
  }

  .myapprochCard2 {
    height: auto;
  }

  .myapprochCard2 .myapprochText1 {
    display: block;
  }

  .myapprochCard2 .myapprochText1 .left3In {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .myapprochCard2 .myapprochText1 .left3In h1 {
    font-size: 56px;
  }

  .myapprochCard2 .myapprochText1 .left3In h3 {
    font-size: 25px;
  }

  .myapprochCard2 .myapprochText1 .right3 {
    width: 100%;
  }

  .myapprochCard3 {
    height: auto;
  }

  .myapprochCard3 .myapprochText2 {
    width: 100%;
    padding: 15px;
  }

  .myapprochCard3 .myapprochText2 h2 {
    text-shadow: none;
    font-size: 40px;
  }

  .myapprochCard3 .myapprochText2 p {
    font-size: 25px;
  }

  .myapprochslider {
    padding-top: 15px;
  }

  .myapprochCard4 {
    height: auto;
  }

  .myapprochCard4 .myapprochText3 {
    display: block;
    height: auto;
  }

  .programCard4 {
    height: auto;
  }

  .programCard4 .myapprochText3 {
    width: 100%;
  }

  .moreCard {
    height: 100vh;
  }

  .moreCard .moreText {
    display: grid;
    grid-template-columns: 6fr 0fr;
  }

  .moreCard .moreText .moreLeft .top {
    display: flex;
    line-height: 40px;
    text-align: justify;

    /* justify-content: center;
    align-items: center; */
  }

  .moreCard {
    text-align: justify;
    font-size: 2px;
    font-weight: 100;
    /* width: 100%; */
    height: 973px;
    background-color: rgb(4, 14, 40);
  }

  .moreCard .moreText .moreRight {
    display: none;
  }

  .moreText .moreLeft .top {
    padding: 10px;
  }

  .moreText .moreLeft .top .menu .morePageLink {
    text-decoration: none;
    font-size: 8px;
  }

  .moreText .moreLeft .top1 .utilityMenu .morePageLink1 {
    font-size: 8px;
  }
}

@media screen and (max-width: 420px) {
  .imgCard {
    width: 45%;
    height: 90%;
    top: 20px !important;
    left: 200px !important;
    bottom: 10px;
    word-spacing: 1px;
    position: absolute;
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 360px) {
  .mystoryCard1 {
    margin-bottom: 5px;
  }

  .imgCard {
    width: 42%;
    height: 85%;
    top: 14px !important;
    left: 175px !important;
    bottom: 30px;
    word-spacing: 1px;
    position: absolute;
    padding-bottom: 0px;
  }

  .imgCard .imgCardText {
    text-align: center;
    padding-top: 0px;
    letter-spacing: 1.5px;
    font-weight: bold;

    font-size: 12px;
  }

  .imgCard .imgCardTextHeadPara {
    text-align: center;
    color: #1d1d1d;

    font-weight: 500;
    font-size: 10px;
  }

  .imgCard .imgCardTextPara {
    padding: 0px;
  }

  .imgCard .imgCardTextPara p {
    text-align: center;
    color: #1d1d1d;

    font-weight: 400;
    font-size: 8px;
    /* border: 1px solid red; */
    padding: 0px;
  }

  .imgCard .imgcardButton {
    padding: 0px;
    font-size: 8px;
    font-weight: bold;
    border-radius: 5px;
    color: #112042;
    /* border: 1px solid red; */
  }

  .imgCard .dashbelow {
    width: 206px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
  }

  .box1 {
    padding-top: 20px;
  }

  .mystoryslider {
    padding-top: 10px;
  }

  .mystoryCard3 .mystorylogo {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .mystoryCard3 .mystorylogo .mystoryImg {
    margin: 0px;
    width: 58%;
    padding: 10px;
    height: auto;
  }

  .mystoryCard4 .text3 {
    display: block;
  }

  .mystoryCard5 .text3 h3 {
    font-size: 17px;
  }

  .mystoryCard5 .text3 h1 {
    font-size: 36px;
    text-shadow: none;
  }

  .programCard1 {
    padding-top: 15px;
  }

  .programCard1 .peramhfgfg {
    text-align: justify;
  }

  .programCard3 {
    padding-top: 10px;
  }

  .programslider {
    padding-top: 20px;
  }

  .programCard2mob {
    padding-top: 20px;
  }

  .programImg .programCard .h2 {
    font-size: 9px;
    text-align: center;
  }

  .myapprochImg {
    width: 100%;
  }

  .myapprochImg .myapprochCard {
    position: absolute;
    top: 80px;
    right: 30px;
  }

  .myapprochImg .myapprochCard h2 {
    font-size: 25px;
  }

  .myapprochCard1 {
    height: auto;
    padding: 15px;
  }

  .myapprochCard1 .myapprochText h1 {
    font-size: 30px;
  }

  .myapprochCard2 {
    height: auto;
  }

  .myapprochCard2 .myapprochText1 {
    display: block;
  }

  .myapprochCard2 .myapprochText1 .left3In {
    height: auto;
  }

  .myapprochCard2 .myapprochText1 .left3In h1 {
    font-size: 24px;
  }

  .myapprochCard2 .myapprochText1 .left3In h3 {
    font-size: 10px;
  }

  .myapprochCard3 {
    height: auto;
  }

  .myapprochCard3 .myapprochText2 {
    width: 100%;
  }

  .myapprochCard3 .myapprochText2 h2 {
    text-shadow: none;
    font-size: 28px;
  }

  .myapprochCard3 .myapprochText2 p {
    font-size: 14px;
  }

  .programCard4 {
    height: auto;
  }

  .programCard4 .programText3 h1 {
    text-shadow: none;
  }

  .myapprochCard4 {
    height: auto;
  }

  .myapprochCard4 .myapprochText3 {
    width: 100%;
    display: block;
  }

  .moreText .moreRight {
    display: none;
  }

  .moreRightCard {
    display: none;
  }

  .moreText .moreLeft .top {
    padding: 10px;
  }

  .moreText .moreLeft .top .menu .morePageLink {
    text-decoration: none;
    font-size: 26px;
  }

  .moreText .moreLeft .top1 .utilityMenu .morePageLink1 {
    font-size: 14px;
  }
}

/* ================================Media query End=============================== */

.box-1 h1 {
  text-align: center;

  color: #112042;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 2;
}

.read-more {

  font-size: 20px;
  text-transform: none;
  line-height: 1;
  color: #ff808b;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 0;
  margin-bottom: 5px;
  display: block;
}

.my-bio {
  position: relative;
  background-color: transparent;
  color: #112042;

  font-weight: 500;
  padding: 0 0 0 24px;
  margin: 0;
  border: 0;
  font-size: 20px;
  letter-spacing: 0;
  display: inline-block;
}

.vertical-line {
  width: 1px;
  height: 35px;
  background-color: #112042;
  display: block;
  margin: 25px auto;
}

/* .imgCard {
    position: absolute;
    top: 210px !important;
    left: 150px !important;
    height: 45%;
    width: 27%;
    text-align: center;
    
    background-color: transparent;
    padding: 10px;
    letter-spacing: 3px;
    z-index: 90;
  } */
.accordion-button {
  color: #112042;
}

.small-12 {
  font-size: 18px;

  color: #060264;
}

.accordion {
  font-size: 18px;

  color: #060264;
}

.fa {

  color: #e8e8ec;


}

.bd__title {
  font-size: 20px;
}

.color {
  text-decoration: none;
  color: #112042;
}

.imgCar {
  color: wheat;
  text-decoration: none;
}

.imgCar:hover {
  color: wheat;
}

.box-7 {
  background-color: #17274b;
}

.color1 {
  text-decoration: none;
  color: #eef0f4;

}

.color1:hover {
  color: #eef0f4;
}

.color2 {
  text-decoration: none;
  color: #000;
  align-items: center;
  text-align: justify;
}

.cama {
  color: red;
  font-size: 30px;
}

.slide-text {

  font-weight: 500;
  font-style: italic;
  color: #ff808b;
  text-transform: none;
  font-size: 19px;
  margin-top: 2px;
}

.why {
  margin-top: 25%;
}

.imgcardButton1link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgcardButton1 {
  text-decoration: none;
  color: #112042;
  font-size: 16px;
  font-weight: bold;
  margin-top: 0px;

}

.imgcardButton1:hover {
  color: #ff808b;
}